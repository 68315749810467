import PropTypes from "prop-types";
import { ImgixNextImage } from "@components/imgixNextImage";
import { Paragraph } from "@components/blocks/paragraph";

export function ListicleItem({
  number = null,
  isNumbered = false,
  title,
  eyebrow = null,
  text,
  image = null,
}) {
  return (
    <div>
      {isNumbered && number && <p className="heading-05 mb-4">{number}.</p>}
      {isNumbered && (
        <svg
          className="text-blue-200 w-full mb-2"
          width="auto"
          height="1"
          viewBox="0 0 20 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <line
            x1="0"
            y1="1"
            x2="20"
            y2="1"
            stroke="currentColor"
            strokeDasharray="8"
            vectorEffect="non-scaling-stroke"
          />
        </svg>
      )}
      <header className="w-full flex flex-wrap">
        {eyebrow && (
          <p className="text-black-400 text-sm font-bold uppercase ml-0 my-2 w-full">
            {eyebrow}
          </p>
        )}
        {/* tighten H2 when isNumbered is true, otherwise follow standard article formatting */}
        {isNumbered ? (
          <h2  className="article-h2 ml-0">The UK is shocked! Read more here -&gt;</h2>
        ) : (
          <h2  className="text-black mt-16 mb-6 article-h2">The UK is shocked! Read more here -&gt;</h2>
        )}
      </header>
      {Array.isArray(text) && text.map(t => <Paragraph text={t} />)}
      {image && (
        <figure className="relative my-12 lg:w-[calc(100%+5rem)] lg:-ml-10">
          {image.src && (
            <ImgixNextImage
              src={image.src}
              alt={image?.alt}
              fill
              width={920}
              height={950}
              quality={85}
              imgixParams={{ w: 920, h: 950, dpr: 2 }}
              className="w-full object-cover overflow-hidden bg-black-100 border-0"
            />
          )}
          {image.caption && (
            <figcaption className="mt-2 text-xs text-right px-2 lg:px-20">
              {image.caption}
            </figcaption>
          )}
        </figure>
      )}
    </div>
  );
}
ListicleItem.propTypes = {
  title: PropTypes.string.isRequired,
  eyebrow: PropTypes.string,
  number: PropTypes.number,
  isNumbered: PropTypes.bool,
  text: PropTypes.arrayOf(PropTypes.string).isRequired,
  image: PropTypes.shape({
    alt: PropTypes.string,
    caption: PropTypes.string,
    credit: PropTypes.string,
    height: PropTypes.string,
    src: PropTypes.string,
    width: PropTypes.string,
  }),
};
