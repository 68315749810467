import PropTypes from "prop-types";
import { ImgixNextImage } from "@components/imgixNextImage";
import { PlusFill, MinusFill } from "@icons";

export function ShopListCard({
  title,
  image,
  price,
  addToCart,
  removeFromCart,
  inCart,
  href,
}) {
  return (
    <article className="relative p-4 lg:p-8 bg-white border border-sand-300 rounded-lg h-full flex items-center">
      <a
        className="w-20 lg:w-24"
        href={href}
        target="_blank"
        rel="noreferrer noopener"
      >
        <ImgixNextImage
          loader={uri => uri.src}
          className="w-full object-contain max-h-24 lg:max-h-32"
          width={200}
          height={240}
          src={image?.src}
          alt={image?.alt}
        />
      </a>
      <div className="ml-6 lg:flex lg:items-center grow">
        <div className="lg:mr-6">
          <p className="text-black text-lg xl:text-2xl mb-2 lg:mb-3">
            <a
              className="text-current"
              href={href}
              target="_blank"
              rel="noreferrer noopener"
            >
              {title}
            </a>
          </p>
          <p className="lg:text-lg lg:font-semibold lg:text-black">{price}</p>
        </div>
        {inCart ? (
          <button
            type="button"
            className="text-blue mt-4 lg:mt-0 font-bold flex items-center shrink-0 lg:ml-auto lg:mr-0 lg:border lg:border-black-300 lg:hover:border-blue lg:rounded-lg lg:px-4 lg:py-2"
            onClick={removeFromCart}
          >
            <span className="mr-2 p-0.5 border border-current rounded-full w-4 h-4">
              <MinusFill className="w-full h-full" />
            </span>
            Remove from Cart
          </button>
        ) : (
          <button
            type="button"
            className="text-blue mt-4 lg:mt-0 font-bold flex items-center shrink-0 lg:ml-auto lg:mr-0 lg:border lg:border-black-300 lg:hover:border-blue lg:rounded-lg lg:px-4 lg:py-2"
            onClick={addToCart}
          >
            <span className="mr-2 p-0.5 border border-current rounded-full w-4 h-4">
              <PlusFill className="w-full h-full" />
            </span>
            Add to Cart
          </button>
        )}
      </div>
    </article>
  );
}

ShopListCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  price: PropTypes.string,
  href: PropTypes.string,
  addToCart: PropTypes.func,
  removeFromCart: PropTypes.func,
  inCart: PropTypes.bool,
};
